import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Layout, Seo } from "@components"
import { animated_link, page_wrapper } from "@css/global"
import { Icon } from "@components"

const ErrorPage = () => {
  return (
    <Wrapper>
      <Layout is404={true}>
        <Seo title={"Oops! 404 Error"} />
        <div className="error-page__wrapper">
          <div>
            <Icon />
          </div>
          <h3>Hmm - this page doesn't exist.</h3>
          <Link to="/">Why not head back to our home page instead?</Link>
        </div>
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.article`
  main {
    width: 100%;
  }

  article {
    padding: 0 !important;
  }

  .error-page__wrapper {
    ${page_wrapper};

    .icon__wrapper {
      position: static;
      opacity: 1;
      margin-bottom: 3rem;

      h2 {
        opacity: 1;
      }
    }
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    text-align: center;
    h3 {
      width: 100%;
      font-size: 1.5rem;
    }

    ${animated_link};

    a {
      margin-top: 1.5rem;
    }
  }
`

export default ErrorPage
